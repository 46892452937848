import { useStaticQuery, graphql } from "gatsby"

export const getMainNavigation = () => {
  const { contentfulSupportSiteOverview } = useStaticQuery(
	graphql`
	  query mainMenuQuery {
		  contentfulSupportSiteOverview {
			mainNavigation {
			  linkUrl
			  title
			}
		  }
		}
	`
  )
  //console.log(contentfulSupportSiteOverview.mainNavigation);
  return contentfulSupportSiteOverview
}