import React from 'react'
import { Link } from 'gatsby'

//import styles from './article-preview.module.css'
import styles from './alert.module.scss'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Collapsible from 'react-collapsible';

const Bold = ({ children }) => <strong>{children}</strong>;
const Text = ({ children }) => <p>{children}</p>;

class EntryHyperlink extends React.Component {
  render() {
    const fields = this.props.fields
    const content = this.props.content
    
    var pageSlug = '';
    var linkTitle = ''

    if(this.props.content) {
      linkTitle = this.props.content.value
    }
    else {
      linkTitle = fields.title[`en-US`]
    }
    if(this.props.fields) {
      if(fields.productReference) {
        pageSlug += `/${fields.productReference['en-US'].fields.slug['en-US']}`;
      }

      if(fields.articleCategoryTopic) {
        pageSlug += `/${fields.articleCategoryTopic['en-US'].fields.slug['en-US']}`;
      }
      if(fields.pageTopic) {
        pageSlug += `/${fields.pageTopic['en-US'].fields.slug['en-US']}`;
      }
      
      if(fields.slug) {
        pageSlug += `/${fields.slug['en-US']}/`;
      }
    }
    var linkReact = <Link to={pageSlug}>{linkTitle}</Link>

    return ( linkReact
      
      )
  }
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.ENTRY_HYPERLINK]: (node) => {
       const fields = node.data.target.fields;
        const content = node.content[0];
        if(node.data.target.sys.contentType != null) {
          //console.log(node.data.target.sys.contentType)  
        }
        else {
          //console.log("no contentType!")
        }
        var nodeType = ''
        
        if (typeof(node.data.target.sys.contentType) !== 'undefined') {
          nodeType = node.data.target.sys.contentType.sys.id
        }
         // if(node.data.target.sys.contentType.sys.id) {
         //  nodeType = node.data.target.sys.contentType.sys.id
         // }
         // console.log(content)
        //return fields.id
        if(nodeType == 'supportSiteArticle' || nodeType == 'supportSitePage') {
          return <EntryHyperlink content={content} fields={fields}/>
        }
        else {
          return null
        }
    }

 },
 renderText: text => {
   return text.split('\n').reduce((children, textSegment, index) => {
     return [...children, index > 0 && <br key={index} />, textSegment];
   }, []);
 },
};


class Alert extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (!this.props.alertIndex || this.props.alertIndex.totalCount == 1) {
       return null;
     }



     var carouselId = "alertCarousel"

     var alertMessage = ''

     var totalAlerts = this.props.alertIndex.totalCount - 1;
     if(totalAlerts > 1) {
      alertMessage = totalAlerts + " Service Alerts"
     }
     else {
      alertMessage = totalAlerts + " Service Alert"
     }

     const allAlerts = this.props.alertIndex.edges
     //console.log(allAlerts)

     var carouselControls = '';
     var slideIndicators = '';

     let indicators = [];


     var firstElement = true
     
     var collapsibleTrigger = <span><i className={"icon-alert"}></i> {alertMessage}</span>

      return (
        <section className={styles.alertSection}>
        <Collapsible trigger={collapsibleTrigger} easing={'cubic-bezier(.38,.13,.11,1.01)'} triggerTagName={'a'} classParentString={styles.newAlert} contentInnerClassName={styles.innerContent} triggerClassName={styles.isClosed} triggerOpenedClassName={styles.isOpen} transitionTime={320}>
         { allAlerts.map((alert, i) => {
           if(alert.node.title != 'dummy'){
            return (
              <div key={alert.node.id}>
              <h3>{alert.node.title}</h3>
              {documentToReactComponents(alert.node.body.json, options)}</div>
            )
          }
          })}
        </Collapsible>
              
         </section>
  )
}
}

export default Alert
