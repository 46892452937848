import React from 'react'
import { Link } from 'gatsby'
import FooterLegal from './footer-legal'
import FooterLogos from './footer-logos'
//import Footnotes from './footnotes'
//import FooterNavigation from './footer-navigation'
import FooterUpper from './footer-upper'
import FooterTerms from './footer-terms'

import styles from './footer.module.scss'

export default class Footer extends React.Component {
  render() {
    return <footer role={"contentinfo"} className={styles.flex}>
   <FooterUpper/>
   <FooterTerms/>
    {/* <FooterNavigation />
     <FooterLogos />
     <FooterLegal currentYear={this.props.currentYear}/> */}
     
   </footer>
  }
}
