import React from 'react'
import { getFooterNavigation } from "../hooks/get-footer-navigation"
import styles from './footer-terms-menu.module.scss'
import YouviewLogo from '../components/youview-logo-navigation'

//import styles from './alert.module.scss'
function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export default function footerTermsMenu() {
	const { footerNavigationLower } = getFooterNavigation()
	// console.log(footerNavigationLower)
	// console.log(Array.isArray(footerNavigationLower));
	var footerNavigationItems = footerNavigationLower.map((menuItem,i) => {
		//console.log(menuItem)
		var linkCode = ''
	//   let slug = '/'
	//   var linkCode = ''
	//   var menuTitle = menuItem.title
	//   
	//   
	// 
	//   if (menuItem.pageItem) {
	// 	 slug = `/${menuItem.pageItem.slug}/`
	// 	if(menuItem.pageItem.parentPage) {
	// 	  
	// 	  slug = `/${menuItem.pageItem.parentPage.slug}/${menuItem.pageItem.slug}/`
	// 	}
	// 	linkCode = <Link  activeClassName={styles.active} to={slug}>{menuTitle}</Link>
	//   }
	//   else if (menuItem.link) {
	// 	var linkIsUrl = false;
	// 	// console.log('menu item is a link')
	// 	// console.log('link is '+menuItem.link)
	// 	linkIsUrl = isURL(menuItem.link)
	// 	if(linkIsUrl === true){
	// 	   // console.log('URL '+menuItem.link+' IS AN EXTERNAL LINK')
	// 	  linkCode = <a href={menuItem.link} target="_blank" >{menuTitle}</a>
	// 	}
	// 	else {
	// 	  slug = `/${menuItem.link}/`
	// 	  // console.log('slug is '+slug)
	// 	  linkCode = <Link  activeClassName={styles.active} to={slug}>{menuTitle}</Link>  
	// 	}
	// 	
	// 	
	//   }
	var linkIsUrl = false;
	linkIsUrl = isURL(menuItem.linkUrl)
	if(linkIsUrl === true){
	   // console.log('URL '+menuItem.link+' IS AN EXTERNAL LINK')
	  linkCode = <a href={menuItem.linkUrl}>{menuItem.title}</a>
	}
		return <li key={i}>{linkCode}</li>
	  
	})
	return <div className={styles.termsMenuContainer}>
	<div className={styles.termsLinks}>
	<YouviewLogo />
	<ul className={styles.privacyMenu}>{footerNavigationItems}</ul>
	</div>
	<div className={styles.containerSocialIcons}><a title="Youview on Linked In" href="https://www.linkedin.com/company/youview-tv-limited/" target="_blank" className={"icon-social-link-block w-inline-block"}><div className={"icon-socials w-embed"}><svg width="22" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M5.268 20.984V7.49H.766v13.494h4.502ZM3.017 5.647c1.57 0 2.547-1.036 2.547-2.331C5.534 1.992 4.587.984 3.047.984S.5 1.992.5 3.316c0 1.295.977 2.331 2.488 2.331h.029ZM7.759 20.984h4.501v-7.536c0-.403.03-.806.148-1.094.326-.806 1.067-1.64 2.31-1.64 1.63 0 2.28 1.237 2.28 3.051v7.22H21.5v-7.738c0-4.145-2.22-6.074-5.183-6.074-2.428 0-3.494 1.353-4.087 2.274h.03V7.49H7.76c.059 1.266 0 13.494 0 13.494Z" fill="currentColor"></path></svg></div></a></div>
	</div>

	
}