import React, { Component, useRef } from "react"
import { Index } from "elasticlunr"

import { Link, navigate } from 'gatsby'

import styles from './hero.module.scss'

function boldString(str, find){
  
    let findVal = find.replace(/\W/g, '');
    var re = new RegExp(findVal, 'g');
    return str.replace(re, '<strong>'+findVal+'</strong>');
}

class SearchResultListing extends React.Component {
  render() {
    const page = this.props.contentNode;
    const query = this.props.query;
    var pageType = "Support";
    var pageCategory = '';
  
    var title = page.title;

    if(page.category){
      pageCategory = page.category;
    }
    if(page.product){
      pageType = page.product;
    }

    var pageSlug = ''
    if(page.productSlug !== ' ') {
      pageSlug += `/${page.productSlug}`;
    }

    if(page.categorySlug !== ' ') {
      pageSlug += `/${page.categorySlug}`;
    }
    
    if(page.slug) {
      pageSlug += `/${page.slug}/`;
    }


    if(this.props.query){
      title = boldString(title,query)
      title = <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
    }
  return (
    <li tabIndex={0}><Link className={"dropdown-item"} role={"option"} state={{ fromSearch: true }} to={pageSlug} onMouseDown={event => {event.preventDefault()}}><span>{title}<br/><small>{pageType}</small></span> <small>{pageCategory}</small></Link></li>
    )
}
}

class Search extends React.Component {
  
  componentDidMount() {
     const values = location
     
  }
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      focused: false,
    }
    //const inputRef = useRef(null);
    this.inputRef = React.createRef();
  }
  onBlur = () => {
      setTimeout(() => {
                 this.setState({
                 focused: false
               })
                 this.props.updateGrandpaState("unfilled");
             }, 120);
  }
  onFocus= () =>{
      this.setState({ focused: true }); 
      this.props.updateGrandpaState("filled");
  }

  clearQuery = () => {
       this.setState({
         query: ``,
         results: []
       });
     }
     
  render() {

        var panelClass = styles.searchResults;
        var ariaExpanded = false
        var searchQueryString = ''
        //console.log(this.props.headerReference)

    if (this.state.focused) {
      //this.props.updateGrandpaState(true);
    }
    


    if(this.state.query !== '') {
      panelClass = [styles.searchResults, styles.active].join(' ')
    }
    // if(this.state.focused && this.state.query.length > 1) {
    //   this.onBlur();
    // }

    if(this.state.results.length > 0 && this.state.focused) {
      ariaExpanded = true
    }
  

    return (
      <form role={"search"} ref={this.props.headerReference} className={panelClass} onSubmit={event => {
      event.preventDefault()
this.inputRef.current.blur();
      // Implementation of this function is an exercise for the reader
      navigate(
        "/search-results/",
        {
          state: { searchQueryString: this.state.query, 
            results: this.state.results
           },
        }
      )
    }}>
        <div>
              <input type={"search"} autoComplete={"off"} id="Form_Search" ref={this.inputRef} name={"q"} value={this.state.query} onFocus={this.onFocus} onBlur={this.onBlur} onChange={this.search} placeholder="Search support or enter an Error code" aria-expanded={ariaExpanded} aria-label="Enter your search term." title="Enter your search term." className={['dropdown-toggle', styles.inputBox].join(' ')} data-provide="typeahead" data-items="4" />
              <ul aria-labelledby={"Form_Search"} className={[styles.typeaheadResults, 'dropdown-menu'].join(' ')}  role={`listbox`}>
                {this.state.results.map(page => (
                  <SearchResultListing key={page.id} query={this.state.query} contentNode={page}/>
                ))}

              </ul>
              <i onClick={this.clearQuery} className={['form-control-clear icon-x form-control-feedback', styles.formControlClear].join(' ')}></i>

              <button id="Form_Go" aria-label="Search" type={"search"} className={['btn btn-primary empty', styles.Button].join(' ')}>
                <i className={"icon-search icon-white"}></i> Search
              </button>
              </div>
      </form>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
      .search(query, { 
        fields:{
          title: 
          {boost:2,bool:"AND", expand: true},
          description:{boost:1, expand: true},
          category:{boost:1},
          product:{boost:1},
          keywords:{boost:1,expand: true}
        },
        bool:"AND" }) // Accept partial matches
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
export default Search
