import React from 'react'
import { StaticQuery, Link } from "gatsby"
import { graphql } from "gatsby"

//import styles from './alert.module.scss'

import Alert from "./alert"

const AlertSection = () => (
  <StaticQuery
    query={graphql`
     query getAlerts {
       allContentfulSupportSiteAlert {
         totalCount
         edges {
           node {
             title
             id
             body {
               json
             }
           }
         }
       }
     }
    `}
    render={data => (
        <Alert alertIndex={data.allContentfulSupportSiteAlert} />
    )}
  />
)

export default AlertSection