import React from "react"
import { useStaticQuery, StaticQuery, Link } from "gatsby"
import { graphql } from "gatsby"

import Search from "./search-bar"


const SearchHeader = ({updateGrandpaState}) => {
  //const testdata = updateGrandpaState;
      const data = useStaticQuery(graphql`
  query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
  `)
    return (
     <Search updateGrandpaState={updateGrandpaState} searchIndex={data.siteSearchIndex.index} />
      )
}
//
export default SearchHeader