import React from 'react'
import { getMainNavigation } from "../hooks/get-main-navigation"
import styles from './navigation-menu.module.scss'

//import styles from './alert.module.scss'
function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export default function navigationMenu() {
	const { mainNavigation } = getMainNavigation()
	
	var mainNavigationItems = mainNavigation.map((menuItem,i) => {
		
		var linkCode = ''
		var linkIsUrl = false;
		
		linkIsUrl = isURL(menuItem.linkUrl)
		
		if(linkIsUrl === true){
			linkCode = <a href={menuItem.linkUrl}>{menuItem.title}</a>
		}
		
		return <li key={i}>{linkCode}</li>
		
	})
	return <ul className={styles.navigationMenu}>{mainNavigationItems}</ul>
}