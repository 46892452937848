import React from 'react'
import { Link } from 'gatsby'

//import styles from './style.scss'
//import styles from './sass/style.scss'
import styles from './newnavigation.module.scss'

import NavigationMenu from '../components/navigation-menu'
import YouviewLogo from '../components/youview-logo-navigation'

//className={[styles.navbar, styles.navbarFixedTop, styles.topNavCollapse].join(' ')}

class NewNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  showAll = () => {
       this.setState({
         open: !this.state.open
       });
     }
  render() {
    
   //const { mainNavigation } = getMainNavigation()
//console.log(mainNavigation);

    var overlayClass = [styles.overlay, styles.overlayScale].join(' ')



 if (this.state.open) {
  navbarClass = [styles.navbar, styles.navbarFixedTop, styles.topNavCollapse, styles.overlayOpen].join(' ')
  overlayClass = [styles.overlay, styles.overlayScale, styles.open].join(' ')
}


    var navbarClass = [styles.navbar, styles.navbarFixedTop, styles.topNavCollapse].join(' ')

    var overlayClass = [styles.overlay, styles.overlayScale].join(' ')


    if (this.state.open) {
      navbarClass = [styles.navbar, styles.navbarFixedTop, styles.topNavCollapse, styles.overlayOpen].join(' ')
      overlayClass = [styles.overlay, styles.overlayScale, styles.open].join(' ')
    }
 {/* <div data-w-id="8cb780b6-a219-3cf4-9626-3e008e4ef0ca" data-animation="over-right" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
   <div class="section_navbar w-container">
     <div class="nav_flex_wrapper">
       <a href="index.html" aria-current="page" class="brand_logo w-nav-brand w--current"><img src="images/Youview-logo.svg" loading="lazy" alt="Youview"></a>
       <nav role="navigation" class="nav-menu w-nav-menu">
         <a href="index.html" aria-current="page" class="nav_link w-nav-link w--current">Youview</a>
         <a href="careers.html" class="nav_link w-nav-link">Careers</a>
         <a href="contact.html" class="nav_link w-nav-link">CONTACT</a>
       </nav>
       <div class="menu-button w-nav-button">
         <div class="w-icon-nav-menu"></div>
       </div>
     </div>
   </div>
 </div> */}

  return (
    <div className={styles.navbar}>
    <div className={styles.sectionNavbar}>
    <div className={styles.navFlexWrapper}>
    <YouviewLogo />
    <button onClick={this.showAll} id={"trigger-overlay"} className={styles.hamburger} aria-label={"Toggle YouView navigation"} type="button">
<span></span>
    </button>
    <NavigationMenu />
    <div id={"trigger-overlay-huge"} onClick={this.showAll} className={overlayClass}>
    <i className={['icon-x', 'st'].join(' ')}></i>
    <NavigationMenu />
    </div>
    </div>
    </div>
    </div>
)
}
}
export default NewNavigation
