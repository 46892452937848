import { useStaticQuery, graphql } from "gatsby"

export const getHeroImage = () => {
  const { contentfulSupportSiteOverview } = useStaticQuery(
	graphql`
	  query getHeroImage {
contentfulSupportSiteOverview {
		  heroImage {
			 fixed(width: 2400, quality:80) {
					  base64
				  aspectRatio
				  width
				  height
				  src
				  srcSet
				  srcWebp
				  srcSetWebp
					}
					fluid(maxWidth: 2400, quality:80) {
					  aspectRatio
					  sizes
					  src
					  srcSet
					  srcSetWebp
					  srcWebp
					}
		  }
		}
	  }
	`
  )
   //console.log(contentfulSupportSiteOverview);
  return contentfulSupportSiteOverview.heroImage
}