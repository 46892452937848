import React from 'react'
import Link from 'gatsby-link'

import styles from './get-contact-section.module.scss'
import {sectionHeading} from '../components/section-heading.module.scss'

// export default () => (

// )


class GetContactSection extends React.Component {


  render() {
const title = this.props.title;
var titleDisplay = ''
if(this.props.title) {
  titleDisplay = title;
}
if (this.props.title && title == "Useful Contacts") {
   return null;
}


    return (
  <section className={styles.getInTouch}>
  <div className={"container"}>
    <div className={"lead-section text-center"}>
      <h2 className={sectionHeading}>
        Get in touch
      </h2>
      <p>Need more help? Visit our full contacts page <Link to={`/useful-contacts/`}><strong>here</strong></Link></p>
    </div>
    <div className={"row"}>

    </div>
  </div>
  </section>
    )
  }
}

export default GetContactSection
