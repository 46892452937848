import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { graphql } from "gatsby"


import styles from './hero.module.scss'

import SearchHeader from '../components/search-header'

import HeroImage from '../components/hero-image'




class Hero extends React.Component {



  constructor(props) {
      super(props)
      this.SearchHeader1 = React.createRef()
      this.state = {
        childFocused: false,
        filled: 'unfilled',
        showSitemap: false
      }
      // const { imageData } = getHeroImage()
      // 
      // console.log(imageData)
  }
  updateGrandpaState = (filled) => {
    this.setState({
      filled
    });
  }

  render() { 

    // const data = useStaticQuery(graphql`
    //   query {
    //     
    //       contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}) {
    //          fixed(width: 1800, quality: 100) {
    //           base64
    //           aspectRatio
    //           width
    //           height
    //           src
    //           srcSet
    //           srcWebp
    //           srcSetWebp
    //         }
    //       }
    //     }
    //   
    // `)
    // console.log(data);



       //const myHeroImage = getHeroImage()
      // console.log(myHeroImage )


    var message = "no focus"

    var largeHeading = "How can we help you?"

    var headerImageObject = ''

    var linkClass = ''

    if(this.props.largeHeading && this.props.largeHeading != '') {
      largeHeading = this.props.largeHeading
      linkClass = ''
    }
    else {
      linkClass = styles.linkStyle;
    }

        if(this.props.headerImage && this.props.headerImage != '') {
      // largeHeading = this.props.largeHeading
      // linkClass = ''
      headerImageObject =<Img backgroundColor={`#0a3963`} fixed={this.props.headerImage.fixed} Tag={'figure'} alt={` `}  role={"decoration"}/>

    }


    var headerClass = styles.forumSection
    const searchForm = this.SearchHeader1.current;
    if(this.SearchHeader1.current) {
      //console.log(searchForm.state.focused);
      //message = "FOCUS"
    }
 

    if(this.state.filled == "filled") {
      headerClass = [styles.forumSection, styles.activeSearch].join(' ')
    }

    
return (

          <header className={headerClass}>
          <HeroImage />
          <div aria-hidden={`true`}>
          
          {/* headerImageObject */}
          </div>
          
        <div className={"container"}>
          <div className={"row"}>
            <h1>{largeHeading}</h1>
            <div className={styles.flexContainer}>
             


              </div>
              <div className={styles.flexContainer}>
                <SearchHeader updateGrandpaState={this.updateGrandpaState}/>
                </div>
                <Link className={linkClass} to={"/sitemap/"}>Or see our site map</Link>

            </div>
          </div>
        </header>
)
}
}
export default Hero

 // <form onSubmit={this.handleSubmit}>
 //<SearchHeader updateGrandpaState={this.updateGrandpaState}/>
 //                <div>
 //                  <input type="text" id="Form_Search" name="search" value={this.state.firstName}
 //            onChange={this.handleInputChange} placeholder="Search support or enter an Error code" aria-label="Enter your search term." title="Enter your search term." className={styles.inputBox} data-provide="typeahead" data-items="4" /><i class="form-control-clear icon-x form-control-feedback hidden"></i>
 //                  <button id="Form_Go" aria-label="Search" type="submit" className={['btn btn-primary empty', styles.Button].join(' ')}>
 //                    <i class="icon-search icon-white"></i> Search
 //                  </button></div>
 //                </form>