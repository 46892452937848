import React from 'react'
import { getFooterNavigation } from "../hooks/get-footer-navigation"
import styles from './footer-navigation-menu.module.scss'

//import styles from './alert.module.scss'
function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export default function footerNavigationMenu() {
	const { footerNavigationUpper } = getFooterNavigation()
	//console.log(footerNavigationUpper)
	//console.log(Array.isArray(footerNavigationUpper));
	var footerNavigationItems = footerNavigationUpper.map((menuItem,i) => {
		//console.log(menuItem)
		var linkCode = ''
	//   let slug = '/'
	//   var linkCode = ''
	//   var menuTitle = menuItem.title
	//   
	//   
	// 
	//   if (menuItem.pageItem) {
	// 	 slug = `/${menuItem.pageItem.slug}/`
	// 	if(menuItem.pageItem.parentPage) {
	// 	  
	// 	  slug = `/${menuItem.pageItem.parentPage.slug}/${menuItem.pageItem.slug}/`
	// 	}
	// 	linkCode = <Link  activeClassName={styles.active} to={slug}>{menuTitle}</Link>
	//   }
	//   else if (menuItem.link) {
	// 	var linkIsUrl = false;
	// 	// console.log('menu item is a link')
	// 	// console.log('link is '+menuItem.link)
	// 	linkIsUrl = isURL(menuItem.link)
	// 	if(linkIsUrl === true){
	// 	   // console.log('URL '+menuItem.link+' IS AN EXTERNAL LINK')
	// 	  linkCode = <a href={menuItem.link} target="_blank" >{menuTitle}</a>
	// 	}
	// 	else {
	// 	  slug = `/${menuItem.link}/`
	// 	  // console.log('slug is '+slug)
	// 	  linkCode = <Link  activeClassName={styles.active} to={slug}>{menuTitle}</Link>  
	// 	}
	// 	
	// 	
	//   }
	var linkIsUrl = false;
	linkIsUrl = isURL(menuItem.linkUrl)
	if(linkIsUrl === true){
	   // console.log('URL '+menuItem.link+' IS AN EXTERNAL LINK')
	  linkCode = <a href={menuItem.linkUrl}>{menuItem.title}</a>
	}
		return <li key={i}>{linkCode}</li>
	  
	})
	return <ul className={styles.navigationMenu}>{footerNavigationItems}</ul>

	
}