import React from 'react'
import { Link } from 'gatsby'

import styles from './footer.module.scss'

export default class FooterLegal extends React.Component {
    render() {
        return <div className={styles.legal}>
            <p className={styles.copyright}>© YouView TV Ltd {this.props.currentYear}. All rights reserved.</p>
        </div>;
    }
}
