import React from 'react'

import { StaticQuery, graphql } from "gatsby"
//import get from 'lodash/get'


import { Link } from 'gatsby'
import base from './base.scss'
//import supportStyles from './sass/style.scss'
import Container from './container'

import NewNavigation from './new-navigation'
import Hero from '../components/hero'

import GetContactSection from '../components/get-contact-section'

//import Header from "./search"

import AlertSection from '../components/alert-section'
//import CookieBanner from '../components/cookie-banner'
import packageJson from '../../package.json';


import Footer from './footer'
import CookieConsent from "react-cookie-consent";

import cookieStyles from '../components/cookie-banner.module.scss'

// if (typeof window !== 'undefined') {
//     window.jQuery = window.$ = require('jquery');
//     require('bootstrap');
// }

class Template extends React.Component {
   componentDidMount() {
  

      

  }
  render() {
    const { location, children, title, headerImage } = this.props


    var largeHeading = ''
    var headerImageData = ''

    if(this.props.largeHeading){
      largeHeading = this.props.largeHeading
    }

    if(this.props.headerImage){
      headerImageData = this.props.headerImage
    }

   // largeHeading = 'The page you\'re looking for can\'t be found'



    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
      <NewNavigation />
        {/* <Navigation /> */}
<CookieConsent
          location="bottom"
          buttonText=""
          cookieName="myAwesomeCookieName2"
          style={{ background: "#121212" }}
          buttonStyle={{ color: "#FFFFFF", fontSize: "13px", backgroundColor: "#121212" }}
          expires={150}
          containerClasses={cookieStyles.cookieBanner}
        >
          This site uses cookies. By continuing to browse, you are agreeing to our use of cookies. <a href="https://www.youview.com/policies/cookie-policy" target="_blank">Read our cookie policy</a>.
          
{" "}        </CookieConsent>
        <Hero headerImage={headerImageData} largeHeading={largeHeading}/>
        <AlertSection />
        {children}
        <GetContactSection title={title}/>
        <Footer currentYear={new Date().getFullYear()} />
        <span style={{display:'none'}}>{`YouView Support: v${packageJson.version}`}</span>
      </Container>
    )
  }
}

export default Template
