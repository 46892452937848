import { useStaticQuery, graphql } from "gatsby"

export const getFooterNavigation = () => {
  const { contentfulSupportSiteOverview } = useStaticQuery(
	graphql`
	 query footerQuery {
	   contentfulSupportSiteOverview {
		 footerNavigationUpper {
		   linkUrl
		   title
		 }
		 footerNavigationLower {
		   linkUrl
		   title
		 }
	   }
	 }
	`
  )
  //console.log(contentfulSupportSiteOverview);
  return contentfulSupportSiteOverview
}